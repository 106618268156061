import { Button, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import Alert from "./Alert";
import Loading from "./Loading";
import getUrlParams from "../util/getQueryParams";
import { formatPhoneNumber } from "../util/phoneUtils";

const SERVER_URL = "https://app.chirrup.chat";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100vh",
		width: "100vw",
		overflowY: "scroll",
		background: theme.palette.common.white,
		"&::-webkit-scrollbar": {
			width: 0,
		},
	},
	body: {
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
	},
	nameContainer: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		gridColumnGap: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
	inputContainer: {
		margin: `${theme.spacing(2)}px 0`,
	},
	disclaimer: {
		fontSize: "0.65rem",
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
		"&:disabled": {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.grey[500],
		},
	},
	powerText: {
		color: theme.palette.grey[800],
		fontWeight: "bold",
	},
	alertRoot: {
		width: "100%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

export default function ChatInput() {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	// const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [firstNameError, setFirstNameError] = useState("");
	const [phoneError, setPhoneError] = useState("");
	const [messageError, setMessageError] = useState("");
	const [messageSent, setMessageSent] = useState(false);
	const [messageSentError, setMessageSentError] = useState(false);
	const [setupError, setSetupError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [company, setCompany] = useState("");

	const classes = useStyles();
	const queryParams = getUrlParams();
	const companyId = queryParams.companyId;

	const getCompanyName = async () => {
		try {
			const response = await fetch(
				`${SERVER_URL}/companies/${companyId}`
			);
			const company = await response.json();
			setCompany(company.name);
		} catch (error) {
			setSetupError(true);
		}
	};

	useEffect(() => {
		getCompanyName();
	}, []);

	if (!companyId || !company) {
		return (
			<>
				<Loading />
				<Alert isOpen={setupError} severity="error">
					An Error Occoured while setting up!
				</Alert>
			</>
		);
	}

	const validate = () => {
		setFirstNameError("");
		setPhoneError("");
		setMessageError("");

		const phoneRe =
			/^\s*(?:\+?1)?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
		let hasError = false;

		if (firstName.length === 0) {
			setFirstNameError("First Name cannot be empty");
			hasError = true;
		}

		if (phone.length === 0) {
			setPhoneError("Phone cannot be empty");
			hasError = true;
		} else if (!phone.match(phoneRe)) {
			setPhoneError(`Invalid phone number`);
			hasError = true;
		}

		if (message.length === 0) {
			setMessageError("Message cannot be empty");
			hasError = true;
		}

		return hasError;
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		setMessageSentError(false);

		if (validate()) {
			setIsLoading(false);
			return;
		}

		if (!companyId) {
			setIsLoading(false);
			setMessageSentError(true);
			return;
		}

		try {
			await fetch(`${SERVER_URL}/companies/${companyId}/conversations`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				referrerPolicy: "no-referrer",
				body: JSON.stringify({
					conversation: {
						first_name: firstName,
						last_name: lastName,
						// email: email,
						phone: formatPhoneNumber(phone) || phone,
						message: message,
					},
				}),
			});
		} catch (error) {
			setMessageSentError(true);
			setIsLoading(false);
			return;
		}

		setFirstName("");
		setLastName("");
		// setEmail("");
		setPhone("");
		setMessage("");
		setIsLoading(false);
		setMessageSent(true);
	};

	return (
		<div className={classes.root}>
			<Paper className={classes.body} elevation={0}>
				<Typography variant="subtitle2">
					Enter your info and we'll text you back
				</Typography>

				<div className={classes.nameContainer}>
					<div>
						<TextField
							value={firstName}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => setFirstName(e.target.value)}
							label="First Name"
							error={firstNameError.length > 0}
							helperText={firstNameError}
							variant="outlined"
							fullWidth
						/>
					</div>
					<div>
						<TextField
							value={lastName}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => setLastName(e.target.value)}
							label="Last Name"
							variant="outlined"
							fullWidth
						/>
					</div>
				</div>
				{/* <div className={classes.inputContainer}>
					<TextField
						value={email}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setEmail(e.target.value)
						}
						label="Email"
						variant="outlined"
						type="email"
						fullWidth
					/>
				</div> */}
				<div className={classes.inputContainer}>
					<TextField
						value={phone}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setPhone(e.target.value)
						}
						label="Phone"
						error={phoneError.length > 0}
						helperText={phoneError}
						variant="outlined"
						fullWidth
					/>
				</div>
				<div className={classes.inputContainer}>
					<TextField
						value={message}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setMessage(e.target.value)
						}
						label="Message"
						error={messageError.length > 0}
						helperText={messageError}
						variant="outlined"
						rows={4}
						multiline
						fullWidth
					/>
				</div>

				<Typography variant="subtitle2" className={classes.disclaimer}>
					By submitting, you authorize
					<strong> {company} </strong>
					to send text messages with offers & other information,
					possibly using automated technology, to the number you
					provided. Message/data rates apply. Consent is not a
					condition of purchase.
				</Typography>
				<br />

				<Button
					className={classes.button}
					onClick={handleSubmit}
					disabled={isLoading}
				>
					Send
				</Button>
				<br />

				<Typography
					variant="subtitle2"
					align="center"
					className={classes.powerText}
				>
					Powered by Chirrup
				</Typography>

				<Alert
					isOpen={messageSent}
					onCloseHandler={() => setMessageSent(false)}
				>
					Message Received! We will get back to you soon
				</Alert>
				<Alert
					isOpen={messageSentError}
					onCloseHandler={() => setMessageSentError(false)}
					severity="error"
				>
					An Error Occoured!
				</Alert>
			</Paper>
		</div>
	);
}
