import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import ChatWidget from "./components/ChatWidget";

const themeData = createMuiTheme({
	palette: {
		primary: {
			main: "#7c9640",
		},
	},
});

function App() {
	return (
		<ThemeProvider theme={themeData}>
			<ChatWidget />
		</ThemeProvider>
	);
}

export default App;
