import { Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React from "react";

interface IProps {
	autoHideDuration?: number;
	children: React.ReactNode;
	isOpen: boolean;
	severity?: "error" | "info" | "success" | "warning";
	onClickHandler?: () => void;
	onCloseHandler?: () => void;
}

const useStyles = makeStyles((theme) => ({
	alertBody: {
		cursor: "pointer",
	},
}));

const Alert = (props: AlertProps) => {
	const classes = useStyles();

	return (
		<MuiAlert
			elevation={6}
			variant="filled"
			className={classes.alertBody}
			{...props}
		/>
	);
};

const AlertPopUp = ({
	autoHideDuration,
	children,
	isOpen,
	severity,
	onClickHandler,
	onCloseHandler,
}: IProps) => {
	return (
		<Snackbar
			open={isOpen}
			autoHideDuration={onCloseHandler ? autoHideDuration || 2000 : null}
			onClose={onCloseHandler}
		>
			<Alert
				onClose={onCloseHandler}
				severity={severity || "success"}
				onClick={onClickHandler || onCloseHandler}
			>
				{children}
			</Alert>
		</Snackbar>
	);
};

export default AlertPopUp;
