import { makeStyles } from "@material-ui/core";

import styleClasses from "./loading.module.css";
import Logo from "../assets/img/logoV1.png";

const useStyles = makeStyles((theme) => ({
	body: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100vw",
		height: "100vh",
		position: "fixed",
		top: 0,
		left: 0,
		zIndex: 20,
		backgroundColor: theme.palette.primary.main,
	},
}));

export default function Loading() {
	const classes = useStyles();

	return (
		<div className={classes.body}>
			<img src={Logo} className={styleClasses.logo} alt="Chirrup" />
		</div>
	);
}
